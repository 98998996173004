
import { defineComponent } from 'vue';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import {
  Getters as PlanGetters,
  Actions as PlanActions,
} from '@/store/enums/PlanEnums';
import { Payment } from '@/models/PaymentModel';

import { Actions as PaymentActions } from '@/store/enums/PaymentEnums';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { Plan } from '@/models/PlanModel';

const temporaryDescriptions = [
  'Submit Requested Tenant References',
  'Submit Unlimited Tenant References',
  'Search for Tenant References',
  'View Full History of a Tenant Reference',
  'Request Tenant References',
  'Create Unlimited Number of Users/PMs',
];

export default defineComponent({
  name: 'plans-table',
  data: () => ({
    loadTable: false,
    loadStart: false,
    planType: 'month',
    planDescriptions: temporaryDescriptions,
  }),
  async mounted() {
    await this.handleLoadPlans();
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      listPlans: PlanGetters.GET_PLAN,
    }),
    getPlanType() {
      return this.planType;
    },
    sortedPlans() {
      const plans = [...this.listPlans];

      return plans.sort((a, b) => {
        return parseFloat(a.monthly_price) - parseFloat(b.monthly_price);
      });
    },

    planServices() {
      const arr = this.sortedPlans.map((item) => {
        return item.description;
      });

      return _.uniq(_.flattenDeep(arr)) as unknown as Plan;
    },

    pricingTable() {
      const pricingLists = {} as any;

      this.planDescriptions.map((desc) => {
        const priceMap = [] as any;
        this.sortedPlans.map((plan) => {
          priceMap[plan.code] = plan.description.includes(desc);
        });

        const key = desc.toLowerCase().replace(/\s/g, '_');
        pricingLists[key] = { ...priceMap };
      });

      return pricingLists;
    },
  },
  methods: {
    ...mapActions({
      getPlans: PlanActions.PLAN,
      checkout: PaymentActions.DO_CHECKOUT,
    }),

    async handleLoadPlans() {
      if (this.loadTable) return;
      this.loadTable = true;

      return await this.getPlans().then(() => {
        this.loadTable = false;
      });
    },
    choosePlanType(type) {
      this.planType = type;
    },
    checkoutPlan(plan) {
      if (this.loadStart) return;
      this.loadStart = true;

      if (plan.monthly_pricing_key == null) return;

      const payload = {
        accountPlanId: plan.id,
        planType: this.planType,
      };

      return this.checkout(payload).then((data: Payment) => {
        window.location = data.checkout.url as unknown as Location;
      });
    },

    calculatePrice(price, discount) {
      if (discount !== '0' && discount !== null) {
        return parseFloat(price) * parseFloat(discount);
      }

      return parseFloat(price).toFixed(0);
    },
  },
});
