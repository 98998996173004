
import { defineComponent } from 'vue';
import PlansTable from '@/components/tables/PlansTable.vue';
import store from '@/store';

import { Actions as StoreActions } from '@/store/enums/StoreEnums';

export default defineComponent({
  name: 'plan-page',
  components: {
    PlansTable,
  },
  mounted() {
    // show page loading
    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);
  },
  setup() {
    // show page loading
    store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');
  },
});
